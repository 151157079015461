<script>
import Layout from "../../../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "../../../../../app.config";
import axios from "axios";
import Swal from "sweetalert2";
// import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import { ref } from "vue";
import animationData from "@/components/widgets/msoeawqm.json";

export default {
    page: {
        title: "Bot Details",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    setup() {
        const contentQuil = ref("")

        const quilReady = (quil) => {
            console.log("masuk sini");
            let keyboard = quil.getModule("keyboard");
            keyboard.bindings['Enter'] = null; 
            keyboard.bindings[13] = null;
            console.log(keyboard);
        }

        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        return { contentQuil, Toast, quilReady };
    },
    data() {
        return {
            title: "Add Bot",
            items: [
                {
                    text: "Add Auto reply",
                    href: "/",
                },
                {
                    text: "Bot Tambah",
                    active: true,
                },
            ],
            content: "",
            first: "",
            id: 0,
            option: [],
            optionDetil: [],
            // for add
            botList: [],
            botOptionList: [
                {
                    ID: 0,
                    OptionText: "",
                    IsAssign: false,
                    DivisiID: 0, 
                    ReplyWith: 0,
                    Sort: 1,
                },
            ],
            botExclude: [],
            botSelected: [],
            optionUpdateId: 0,
            defaultOptions: {
                animationData: animationData
            },
            divisi: [],
            showWarning: false,
            botLevel: [],
            currentLevel: 0,
            botNew: true
        };
    },
    watch: {
        async $route(to){
            if(Object.keys(to.params).length >= 1){
                this.botNew = false;
                this.id = to.params.id;
                await this.getBotById(to.params.id);
            } else {
                this.id = 0;
                this.showWarning = false;
                this.botNew = true;
            }
        }
    },
    methods: {
        async fetchChatBot(id, idx) {
            let url = `${process.env.VUE_APP_URL_API}/chat-bot/getBot/${id}`;
            const req = await axios
                .get(url, {
                    params: {
                        ClientID: process.env.VUE_APP_CLIENT_ID,
                        AccessToken: localStorage.getItem("jwt"),
                    },
                })
                .catch((err) => {
                    console.log(err);
                });

            let data = req.data;
            if (data.Meta.Code === 200) {
                let contentDB = data.Data.Content.replace(/<p[^>]*>/g, '').replace(/<\/p>/g, '');
                let childern = {
                    id: data.Data.ID,
                    content: contentDB,
                    option: data.Data.Option,
                };
                this.optionDetil[idx]["botReply"] = childern;
            }
        },
        addOption() {
            let sort = this.botOptionList.length + 1;
            this.botOptionList.push({
                ID: 0,
                OptionText: "",
                IsAssign: false,
                ReplyWith: 0,
                DivisiID: 0,
                Sort: sort,
            });
        },
        removeOption(data) {
            if(data.ID){
                Swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.isConfirmed) {
                        let url = `${process.env.VUE_APP_URL_API}chat-bot/deleteSingleOption/${data.ID}`
                        axios.get(url, {
                            params: {
                                ClientID: process.env.VUE_APP_CLIENT_ID,
                                AccessToken: localStorage.getItem("jwt")
                            }
                        }).then(async res => {
                            if(res.data.Meta.Code == 200){
                                this.Toast.fire({
                                    icon: 'success',
                                    text: 'Remove option success'
                                })
                            } else if(res.data.Meta.Code == 205) {
                                Swal.fire({
                                    title: 'Are you sure?',
                                    text: res.data.Meta.Message,
                                    icon: 'warning',
                                    showCancelButton: true,
                                    confirmButtonColor: '#3085d6',
                                    cancelButtonColor: '#d33',
                                    confirmButtonText: 'Yes, delete rekrusive!',
                                    cancelButtonText: 'No, delete rekrusive'
                                }).then((blaem) => {
                                    let url = "";
                                    if(blaem.isConfirmed){
                                        url = `${process.env.VUE_APP_URL_API}chat-bot/deleteSingleOption/${data.ID}?Validation=true&Rekrusif=yes`
                                    } else {
                                        url = `${process.env.VUE_APP_URL_API}chat-bot/deleteSingleOption/${data.ID}?Validation=true&Rekrusif=no`
                                    }
    
                                    axios.get(url, {
                                        params: {
                                            ClientID: process.env.VUE_APP_CLIENT_ID,
                                            AccessToken: localStorage.getItem("jwt")
                                        }
                                    }).then(() => {
                                        this.Toast.fire({
                                            icon: 'success',
                                            text: 'Delete rekrusive success'
                                        })
                                    }).catch(() => {
                                        this.Toast.fire({
                                            icon: 'error',
                                            text: 'Internal Server Error'
                                        })
                                    })
                                })
                            }
    
                            await this.getBotById(this.id)
                        }).catch(() => {
                            this.Toast.fire({
                                icon: 'error',
                                text: 'Internal Server Error'
                            })
                        })                                        
                    }
                })
            } else {
                this.botOptionList.pop();
            }
        },
        loadHtml(delta) {
            this.contentQuil = delta;
        },
        cancelAddOption() {
            let removeList = this.botOptionList.filter((item) => {
                return item.ID === 0;
            });

            removeList.forEach((item) => {
                var idx = this.botOptionList.findIndex((el) => {
                    return el.Sort === item.Sort;
                });

                this.botOptionList.splice(idx, 1);
            });
        },
        async addbot() {
            var optionData = [];
            this.botOptionList.forEach((row) => {
                optionData.push({
                    Text: row.OptionText,
                    IsAssign: row.IsAssign,
                    DivisiID: row.DivisiID,
                    ReplyWith: row.ReplyWith,
                    Sort: row.Sort,
                });
            });

            let urlCheckFirstChat = `${process.env.VUE_APP_URL_API}chat-bot/getFirstChat`
            let isFirst = axios.get(urlCheckFirstChat, {
                params: {
                    kodemb: JSON.parse(localStorage.getItem("user")).Company.CompanyCode
                }
            }).then(res => {
                if(res.status == 200){
                    if(res.data.Meta.Code == 200){
                        return false;
                    } else {
                        return true;
                    }
                }
            }).catch(err => {
                console.log(err);
            })

            var data = JSON.stringify({
                Content: this.contentQuil,
                FirstChat: await isFirst,
                Level: this.currentLevel + 1,
                KodeMB: JSON.parse(localStorage.getItem("user")).Company.CompanyCode,
                Option: optionData,
                UpdateOption: sessionStorage.getItem("OptionID")
            });

            let url = process.env.VUE_APP_URL_API + "chat-bot/createBot";
            url +=
                "?ClientID=" +
                process.env.VUE_APP_CLIENT_ID +
                "&AccessToken=" +
                localStorage.getItem("jwt");
            await axios
                .post(url, data)
                .then((res) => {
                    if(res.data.Meta.Code === 200){
                        this.id = res.data.Data.ID;
                        this.Toast.fire({
                            icon: 'success',
                            title: 'Bot reply success added'
                        })

                        this.botOptionList = [];
                        
                        this.botNew = false;
                        this.$router.push(`/apps/auto-reply/edit/${res.data.Data.ID}`)
                        return;
                    } else {
                        this.Toast.fire({
                            icon: 'warning',
                            title: res.data.Meta.Message
                        })
                    }
                })
                .catch((er) => {
                    console.log(er);
                    Swal.fire("Fails!", er.message, "error");
                });
        },
        addnew(data) {
            if(!this.id || data.ID == 0){
                let text = 'simpan';
                if(data.ID == 0){
                    text = 'update';
                }
                Swal.fire({
                    title: `Data belum di ${text}!`,
                    text: `Mohon ${text} data terlebih dahulu seblum menambah baru!!`,
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Ok'
                })
                return;
            }

            this.content = "";
            this.contentQuil = "";
            // this.$refs.quilEditor.setContents(this.content);
            this.botOptionList = [
                {
                    ID: 0,
                    OptionText: "",
                    IsAssign: false,
                    DivisiID: 0,
                    ReplyWith: 0,
                    Sort: 1,
                },
            ];
            this.botNew = true;

            // document.getElementById("addform").reset();
            /* document.getElementById("modal-id").style.display = "none";
            document.getElementById("exampleModalLabel").innerHTML = "Tambah Jawaban Bot"; */

            setTimeout(function(){
                document.querySelector("#botId-optionId").setAttribute("data-bot-id", this.id);
                document.querySelector("#botId-optionId").setAttribute("data-option-id", data.ID);
                sessionStorage.setItem("OptionID", data.ID);
            }, 200);

            this.$router.push("/apps/auto-reply/add");
        },
        editdata(data) {
            // this.$refs.quilEditor.setContents(data.content);
            this.contentQuil = data.content;
            this.botOptionList = data.option;
            this.$router.push(`/apps/auto-reply/edit/${data.ReplyWith}`)
        },
        updateorder() {

            var id = this.id;

            var optionData = [];
            this.botOptionList.forEach((row) => {
                optionData.push({
                    ID: row.ID,
                    OptionText: row.OptionText,
                    IsAssign: row.IsAssign,
                    ReplyWith: row.ReplyWith,
                    DivisiID: row.DivisiID,
                    Sort: row.Sort,
                });
            });

            var data = JSON.stringify({
                Content: this.contentQuil,
                FirstChat: this.first,
                Level: this.currentLevel,
                KodeMB: JSON.parse(localStorage.getItem("user")).Company.CompanyCode,
                Option: optionData,
            });

            var url = `${process.env.VUE_APP_URL_API}chat-bot/updateChatBot/${id}?AccessToken=${localStorage.getItem("jwt")}&ClientID=${process.env.VUE_APP_CLIENT_ID}`;
            axios.post(url, data).then(async () => {
                console.log("Success updated");
                await this.getBotById(this.id)
                this.Toast.fire({
                    icon: "success",
                    text: "Data success updated"
                })
            }).catch((er) => {
                this.Toast.fire({
                    icon: "error",
                    text: "Internal Server Error"
                })
                console.log(er);
            });
        },
        unlinkanswer(parent){
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, unlink it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    let url = `${process.env.VUE_APP_URL_API}chat-bot/unlinkAnswer`
                    axios.get(url, {
                        params: {
                            BotID: this.id,
                            OptionID: parent.id,
                            AccessToken: localStorage.getItem("jwt"),
                            ClientID: process.env.VUE_APP_CLIENT_ID
                        }
                    }).then(async res => {
                        if(res.data.Meta.Code === 200){
                            this.Toast.fire({
                                icon: "success",
                                text: "Unlink success"
                            })
                            
                            let idxOptionDetil = this.optionDetil.findIndex(el => el.id === parent.id);
                            if(idxOptionDetil !== -1){
                                await this.getBotById(this.id);
                            }
                        } else {
                            this.Toast.fire({
                                icon: "warning",
                                text: res.data.Meta.Message
                            })
                        }
                    }).catch(() => {
                        this.Toast.fire({
                            icon: "error",
                            text: "Internal Server Error"
                        })
                    })
                }
            })
        },
        async getBotById(id)
        {
            await axios.get(`${process.env.VUE_APP_URL_API}/chat-bot/getBot/${id}`, {
                params: {
                    ClientID: process.env.VUE_APP_CLIENT_ID,
                    AccessToken: localStorage.getItem("jwt"),
                },
            }).then(async (res) => {
                let data = res.data;
                if (data.Meta.Code === 200 && data.Data) {
                    this.currentLevel = data.Data.Level;
                    if(sessionStorage.getItem("botLevel")){
                        this.botLevel = JSON.parse(sessionStorage.getItem("botLevel"));
                        const lastLevel = this.botLevel.length - 1;
                        if(data.Data.Level < this.botLevel[lastLevel]){
                            this.botLevel.splice(lastLevel, 1);
                            sessionStorage.setItem("botLevel",JSON.stringify(this.botLevel));
                        } else {
                            let levelPosition = this.botLevel[lastLevel] === data.Data.Level;
                            if(!levelPosition){
                                this.botLevel.push(data.Data.Level);
                            }
                            sessionStorage.setItem("botLevel",JSON.stringify(this.botLevel));
                        }
                    } else {
                        this.botLevel.push(data.Data.Level);
                        sessionStorage.setItem("botLevel",JSON.stringify(this.botLevel));
                    }

                    this.content = data.Data.Content.replace(/<p[^>]*>/g, '').replace(/<\/p>/g, '');
                    this.contentQuil = this.content;
                    this.first = data.Data.First;
                    this.id = data.Data.ID;
                    this.option = data.Data.Option;
                    this.optionDetil = [];
                    this.botOptionList = [];
                    for (let _idx = 0; _idx < data.Data.Option.length; _idx++) {
                        const item = data.Data.Option[_idx];
                        console.log(item);
                        this.optionDetil.push({
                            id: item.ID,
                            content: item.OptionText,
                            isAssign: item.IsAssign,
                            DivisiID: item.DivisiAssign,
                            sort: item.Sort,
                            botReply: {},
                        });

                        this.botOptionList.push({
                            ID: item.ID,
                            OptionText: item.OptionText,
                            IsAssign: item.IsAssign == 1 ? true : false,
                            DivisiID: item.DivisiAssign,
                            ReplyWith: item.ReplyWith,
                            Sort: item.Sort,
                        })

                        if (item.ReplyWith !== 0) {
                            await this.fetchChatBot(
                                item.ReplyWith,
                                this.optionDetil.length - 1
                            );
                        }
                    }

                    if(this.showWarning){
                        let setFalseWarning = this.botOptionList.find(el => {
                            return el.IsAssign != 0 || el.ReplyWith != 0
                        })
                        if(setFalseWarning){
                            this.showWarning = false;
                        }
                    }

                    let checkShowWarning = this.botOptionList.find(el => {
                        return el.IsAssign == 0
                    })

                    if(checkShowWarning){
                        if(checkShowWarning.ReplyWith == 0){
                            this.showWarning = true;
                        }
                    }
                }
            })
            .catch((er) => {
                console.log(er);
                this.Toast.fire({
                    icon: "error",
                    text: "Internal Server Error"
                })
            });
        },
        getBotExclude(optionId)
        {
            this.optionUpdateId = optionId;
            this.botExclude = [];
            var url = `${process.env.VUE_APP_URL_API}chat-bot/getBotExclude/${this.id}`;
            axios.get(url).then((res) => {
                let json = res.data;
                if (json.Meta.Code === 200) {
                    this.botSelected = json.Data.Selected;
                    json.Data.Option.forEach((row) => {
                        if(!this.botSelected.includes(row.ID)){
                            this.botExclude.push({
                                id: row.ID,
                                title: row.Content,
                            });
                        }
                    });
                } else {
                    this.botExclude = [];
                }
            })
            .catch((er) => {
                this.botExclude = [];
                console.log(er);
            });
        },
        updateOptionBot(bot)
        {
            let optionUpdate = [];
            optionUpdate.push({
                ID: this.optionUpdateId,
                ReplyWith: bot.id
            })

            let formData = JSON.stringify({
                Content: this.content,
                FirstChat: this.first,
                KodeMB: JSON.parse(localStorage.getItem("user")).Company.CompanyCode,
                Option: optionUpdate
            })

            let url = `${process.env.VUE_APP_URL_API}chat-bot/updateChatBot/${this.id}`;
            axios.post(url, formData, {
                params: {
                    ClientID: process.env.VUE_APP_CLIENT_ID,
                    AccessToken: localStorage.getItem("jwt")
                }
            }).then(async res => {
                if(res.data.Meta.Code === 200){
                    this.Toast.fire({
                        icon: 'success',
                        text: 'Bot success ditambahkan di opsi'
                    })
                    await this.getBotById(this.id);
                    document.querySelector("#dismissModal").click();
                } else {
                    this.Toast.fire({
                        icon: 'warning',
                        text: res.data.Meta.Message
                    })
                }
            }).catch(err => {
                console.log(err);
                this.Toast.fire({
                    icon: 'error',
                    text: 'Internal Server Error'
                });
            })
        },
        async gotoDetil(id, back = false){
            let url = '';
            if(back){
                await this.$router.go(-1)
            } else {
                url = this.$route.fullPath+"/"+id;
                await this.$router.push(url)
            }
        },
        async backLink(){
            await this.$router.go(-1)
        },
        deleteBot(){
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    let url = `${process.env.VUE_APP_URL_API}chat-bot/deleteBot/${this.id}`
                    axios.get(url, {
                        params: {
                            ClientID: process.env.VUE_APP_CLIENT_ID,
                            AccessToken: localStorage.getItem("jwt")
                        }
                    }).then(res => {
                        if(res.data.Meta.Code === 200){
                            this.Toast.fire({
                                icon: 'success',
                                title: 'Delete Success'
                            })
                            this.backLink();
                        } else {
                            this.Toast.fire({
                                icon: 'error',
                                title: res.data.Meta.Message
                            })
                        }
                    }).catch(err => {
                        console.log(err);
                        this.Toast.fire({
                            icon: 'error',
                            title: 'Internal Server Error'
                        })
                    })
                }
            })
        },
    },
    async mounted(){
        let route = this.$router.currentRoute.value;
        let splitPath = route.fullPath.split("/");
        splitPath = splitPath.filter(el => el != '');
        // this.id = parseInt(splitPath[splitPath.length - 1]);
        if(splitPath.length >= 4){
            this.title = "Edit Bot"
            this.items[0].text = "Edit Auto Reply"
            this.items[1].text = "Bot Update"
            this.id = this.$route.params.id;
            this.botNew = false;
            console.log("dari mounted");
            await this.getBotById(this.id);
        } else {
            if(sessionStorage.getItem("botLevel")){
                let level = JSON.parse(sessionStorage.getItem("botLevel"));
                this.currentLevel = level[level.length - 1];
                level.forEach(lvl => {
                    this.botLevel.push(lvl);
                })
            }
        }

        let url = `${process.env.VUE_APP_URL_API}divisi/get`
        axios.get(url, {
            params: {
                ClientID: process.env.VUE_APP_CLIENT_ID,
                AccessToken: localStorage.getItem("jwt")
            }
        }).then(res => {
            if(res.data.Meta.Code == 200){
                this.divisi = res.data.Data;
            }
        }).catch(err => {
            console.log(err);
        })
    },
    beforeMount() {
        this.content = "";
        this.first = "";
        this.id = 0;
        this.option = [];
        this.optionDetil = [];
    },
    components: {
        Layout,
        PageHeader,
        // QuillEditor
    },
};
</script>

<style>
  .steps {
    /* margin: 40px; */
    padding: 0;
    overflow: hidden;
  }
  .steps a {
    color: white;
    text-decoration: none;
  }
  .steps em {
    display: block;
    font-size: 1.1em;
    font-weight: bold;
  }
  .steps li {
    float: left;
    margin-left: 0;
    width: 150px; /* 100 / number of steps */
    height: 75px; /* total height */
    list-style-type: none;
    padding: 5px 5px 5px 30px; /* padding around text, last should include arrow width */
    border-right: 7px solid white; /* width: gap between arrows, color: background of document */
    position: relative;
  }
  /* remove extra padding on the first object since it doesn't have an arrow to the left */
  .steps li:first-child {
    padding-left: 5px;
  }
  /* white arrow to the left to "erase" background (starting from the 2nd object) */
  .steps li:nth-child(n + 2)::before {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    border-left: 24.5px solid white; /* width: arrow width, color: background of document */
    border-top: 40px solid transparent; /* width: half height */
    border-bottom: 40px solid transparent; /* width: half height */
    width: 0;
    height: 0;
    content: " ";
  }
  /* colored arrow to the right */
  .steps li::after {
    z-index: 1; /* need to bring this above the next item */
    position: absolute;
    top: 0;
    right: -24px; /* arrow width (negated) */
    display: block;
    border-left: 25px solid #7c8437; /* width: arrow width */
    border-top: 40px solid transparent; /* width: half height */
    border-bottom: 40px solid transparent; /* width: half height */
    width: 0;
    height: 0;
    content: " ";
  }

  /* Setup colors (both the background and the arrow) */

  /* Completed */
  .steps li {
    background-color: #7c8437;
  }
  .steps li::after {
    border-left-color: #7c8437;
  }

  /* Current */
  .steps li.current {
    background-color: #c36615;
  }
  .steps li.current::after {
    border-left-color: #c36615;
  }

  /* Following */
  .steps li.current ~ li {
    background-color: #ebebeb;
  }
  .steps li.current ~ li::after {
    border-left-color: #ebebeb;
  }

  /* Hover for completed and current */
  .steps li:hover {
    background-color: #696;
  }
  .steps li:hover::after {
    border-left-color: #696;
  }
</style>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row justify-content-center">
            <div class="col-xxl-9">

                <!-- Warning Alert -->
                <div v-if="showWarning && id" class="alert alert-warning alert-dismissible alert-additional shadow fade show mb-4" role="alert">
                    <div class="alert-body">
                        <div class="d-flex">
                            <div class="flex-shrink-0 me-3">
                                <i class="ri-alert-line fs-16 align-middle"></i>
                            </div>
                            <div class="flex-grow-1">
                                <h5 class="alert-heading">WARNING !!! Salah satu pilihan tidak memiliki response balik</h5>
                                <p class="mb-0">Jika memang tidak memiliki response balik, system menganjurkan untuk menghapusnya atau melakukan assign ke agent terkait. </p>
                            </div>
                        </div>
                    </div>
                    <div class="alert-content">
                        <p class="mb-0">Warning ini dapat mempengaruhi kinerja BOT dalam menjawab pertanyaan calon Pelanggan</p>
                    </div>
                </div>

                <button @click="backLink" type="button" class="btn btn-primary btn-label waves-effect waves-light rounded-pill"><i class=" ri-close-line label-icon align-middle rounded-pill fs-16 me-2"></i> Cancel</button>
                <button v-if="botNew" @click="addbot" type="button" class="btn btn-success btn-label waves-effect waves-light rounded-pill ms-2"><i class="ri-save-line label-icon align-middle rounded-pill fs-16 me-2"></i> Save</button>
                <button v-if="!botNew" @click="updateorder" type="button" class="btn btn-warning btn-label waves-effect waves-light rounded-pill ms-2"><i class="ri-edit-box-line label-icon align-middle rounded-pill fs-16 me-2"></i> Update</button>
                <button v-if="!botNew" @click="deleteBot" type="button" class="btn btn-danger btn-label waves-effect waves-light rounded-pill ms-2"><i class=" ri-delete-bin-6-line label-icon align-middle rounded-pill fs-16 me-2"></i> Delete</button>

                <!-- for leveling -->
                <ul class="steps steps-5 mt-3">
                    <li v-for="(lvl, idx) in botLevel" :key="idx" :class="{'current': idx == botLevel.length - 1}" style="text-align: center; padding: 27.5px 0;">
                        <h5 class="text-white" href="#" title=""><span>Level {{ botLevel[idx] }}</span></h5>
                    </li>
                </ul>
                <!-- end leveling -->

                <div class="card mt-4" id="demo">
                    <!--end card-header-->
                    <div class="card-body p-4">
                        <div class="row g-3">
                            <input type="hidden" id="botId-optionId" data-bot-id="" data-option-id="" />
                            <div class="col-lg-8 col-6">
                                <p class="text-muted mb-2 text-uppercase fw-semibold">
                                    Content Question
                                </p>
                                <h5 class="fs-14 mb-0">
                                    <!-- <QuillEditor v-on:ready="(quil) => this.quilReady(quil)" v-model:content="contentQuil" @update:content="loadHtml(contentQuil)"
                                            theme="snow" :toolbar="['bold', 'italic', 'strike']" contentType="html"
                                            placeholder="Input here" ref="quilEditor"></QuillEditor> -->
                                    <input v-model="this.contentQuil" class="form-control" placeholder="Masukkan content text" maxlength="80" /><br/>
                                    <small>Format jika ingin tulisan bold, italic / strikethrough : <br/>
                                        ( * : Bold )<br/>
                                        ( _ : Italic )<br/>
                                        ( ~ : Strikethrough )
                                    </small>
                                </h5>
                            </div>
                        </div>
                        <!--end row-->
                    </div>
                    <!--end card-body-->
                    <div class="card-body p-4 border-top border-top-dashed">
                        <button @click="addOption" class="btn btn-success">Add Option</button>
                    </div>
                    <!--end card-body-->
                    <div class="card-body p-4">
                        <div class="table-responsive">
                            <table class="table table-borderless text-center table-nowrap align-middle mb-0">
                                <thead>
                                    <tr class="table-active">
                                        <th scope="col" class="text-start" style="width: 50px">
                                            No
                                        </th>
                                        <th scope="col">Option Text</th>
                                        <th scope="col">Assign to Agent</th>
                                        <th scope="col">Select Divisi</th>
                                        <th scope="col" class="text-end">Action</th>
                                    </tr>
                                </thead>
                                <tbody id="products-list">
                                    <tr v-for="(item, index) of botOptionList" :key="index">
                                        <th class="text-start" scope="row">
                                            {{ item.Sort }}
                                        </th>
                                        <td>
                                            <input v-model="item.OptionText" class="form-control" placeholder="Masukkan option text" />
                                        </td>
                                        <td>
                                            <div class="text-center form-check form-check-outline form-check-secondary">
                                                <input v-model="item.IsAssign" :checked="item.IsAssign" :id="`form-assign${item.ID}`" class="form-check-input" type="checkbox">
                                                <label class="form-check-label ms-2" for="formCheck14">
                                                    Assign Reply
                                                </label>
                                            </div>
                                        </td>
                                        <td>
                                            <select v-if="item.IsAssign" v-model="item.DivisiID" :id="`row-selectDivisi-${item.ID}`" class="form-select rounded-pill" aria-label="Default select example">
                                                <option selected value="0">Search for divisi</option>
                                                <option v-for="dv in divisi" :key="dv.ID" :value="dv.ID">{{ dv.Title }}</option>
                                            </select>
                                            
                                            <div v-else class="alert alert-info alert-border-left fade shadow show" role="alert">
                                                <i class="ri-airplay-line align-middle"></i> <strong>Info</strong> Pilihan muncul jika assign di checklist
                                            </div>
                                        </td>
                                        <td class="text-end">
                                            <button v-if="index >= 1" @click="removeOption(item)" type="button" class="btn btn-danger btn-sm btn-icon waves-effect waves-light"><i class="ri-delete-bin-5-line"></i></button>
                                            <button v-if="!item.ReplyWith && this.id && !item.IsAssign" @click="addnew(item)" type="button" class="btn btn-outline-info btn-sm btn-icon waves-effect waves-light ms-2" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Tambah Message Reply"><i class="ri-chat-new-line"></i></button>
                                            <button v-if="item.ReplyWith && !item.IsAssign" @click="editdata(item)" type="button" class="btn btn-warning btn-sm btn-icon waves-effect waves-light ms-2" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Edit Message Reply"><i class="ri-chat-settings-line"></i></button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <!--end table-->
                        </div>
                    </div>
                    <!--end card-body-->
                </div>
                <!--end card-->
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </Layout>
</template>
